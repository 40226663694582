import { PaymentNomination, PaymentStatus } from "@/constants/PaymentConstants";

export default class PaymentFilter {

    public pageNumber = 1;
    public pageSize = 20;
    public nomination: PaymentNomination | null = null;
    public includeNonMonitored: boolean | null = false;
    public status: PaymentStatus | null = null;

}
