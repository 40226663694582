





























































import {Vue, Component} from 'vue-property-decorator';
import {PaymentDTO} from "@/dto/payment/PaymentDTO";
import {namespace} from "vuex-class";
import PaymentsService from "@/services/PaymentsService";
import {processError} from "@/utils/ComponentUtils";
import RouteNames from "@/router/RouteNames";
import PaymentFilter from "@/dto/payment/PaymentFilter";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import { PaymentNomination, PaymentStatus } from "@/constants/PaymentConstants";
import SelectOption from "@/components/common/SelectOption";

const AppModule = namespace("App");

@Component<Payments>({
  components: { PortalCheckbox, PortalSelect, PaginationComponent },
  computed: {
    PaymentStatus() {
      return PaymentStatus
    },
    SelectOption() {
      return SelectOption
    },
    PaymentNomination() {
      return PaymentNomination
    }
  }
})
export default class Payments extends Vue {

  @AppModule.Action
  private startLoading!: () => void
  @AppModule.Action
  private stopLoading!: () => void

  payments: Array<PaymentDTO> | [] = [];
  filter = new PaymentFilter();
  private totalPages = 1;

  mounted(): void {
    this.loadPayments();
  }

  loadPayments(): void {
    this.startLoading();
    PaymentsService.getPayments(this.filter).then(
        ok => {
          this.payments = ok.data.content;
          this.totalPages = ok.data.totalPages;
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  gotoPayment(payment: PaymentDTO): void {
    this.$router.push({name: RouteNames.ARCHIVE_PAYMENT, params: {id: `${payment.id}`}});
  }

  private changePage(page: number): void {
    this.filter.pageNumber = page;
    this.loadPayments();
  }

  getRowColor(payment: PaymentDTO): string {
    const expirationDate = new Date(payment.actualPaymentDate || payment.scheduledPaymentDate);
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);

    if (expirationDate < today) {
      return 'expired-row';
    } else if (expirationDate < nextWeek) {
      return 'expiring-soon-row';
    } else {
      return '';
    }
  }

}
